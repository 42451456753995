<template>
  <div class="role-detail-component app-container">
    <Permission :currentRow="currentRow" />
  </div>
</template>

<script>
import Permission from "./permission";
export default {
  components: {
    Permission,
  },
  created() {
    const { roleName, roleCode, roleId, tenantId } = this.$route.query;
    this.currentRow = { roleName, roleCode, roleId, tenantId };
    this.$eventBus.$emit("setDetailAlias", roleName);
  },
  mounted() {},
};
</script>

<style>
</style>