var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "role-permission-component",
    },
    [
      _c("div", { staticClass: "button-line" }, [
        _c("span", { staticClass: "title-span" }, [_vm._v(" 权限清单 ")]),
        _c(
          "span",
          [
            _c("el-button", { on: { click: _vm.goBackHandler } }, [
              _vm._v("放弃更改"),
            ]),
            _c(
              "el-button",
              {
                attrs: { type: "primary" },
                on: {
                  click: function ($event) {
                    return _vm.savePermission()
                  },
                },
              },
              [
                _vm._v(
                  "\n        " + _vm._s(_vm.$t("commons.save")) + "\n      "
                ),
              ]
            ),
          ],
          1
        ),
      ]),
      _c(
        "el-row",
        { staticClass: "permissionContent" },
        [
          _c(
            "el-collapse",
            {
              model: {
                value: _vm.activeNames,
                callback: function ($$v) {
                  _vm.activeNames = $$v
                },
                expression: "activeNames",
              },
            },
            _vm._l(_vm.treeData, function (node) {
              return _c(
                "el-collapse-item",
                {
                  key: node.id,
                  staticClass: "ruge-label-single",
                  attrs: { name: node.id },
                },
                [
                  _c(
                    "template",
                    { slot: "title" },
                    [
                      _c(
                        "el-checkbox",
                        {
                          key: node.id,
                          staticClass: "ruge-label-t",
                          attrs: {
                            disabled: _vm.checkDisabled,
                            indeterminate: node.indeterminate,
                          },
                          on: {
                            change: function ($event) {
                              return _vm.renderCheck(node)
                            },
                          },
                          model: {
                            value: node.check,
                            callback: function ($$v) {
                              _vm.$set(node, "check", $$v)
                            },
                            expression: "node.check",
                          },
                        },
                        [_vm._v(_vm._s(node.label))]
                      ),
                    ],
                    1
                  ),
                  _vm._l(node.children, function (node_s1) {
                    return _c(
                      "div",
                      { key: node_s1.id, staticClass: "ruge-label-content" },
                      [
                        _c(
                          "div",
                          { staticClass: "ruge-label-content-s1" },
                          [
                            _c(
                              "el-checkbox",
                              {
                                key: node_s1.id,
                                staticClass: "ruge-label-btn",
                                attrs: {
                                  disabled: _vm.checkDisabled,
                                  indeterminate: node_s1.indeterminate,
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.renderCheck(node_s1)
                                  },
                                },
                                model: {
                                  value: node_s1.check,
                                  callback: function ($$v) {
                                    _vm.$set(node_s1, "check", $$v)
                                  },
                                  expression: "node_s1.check",
                                },
                              },
                              [_vm._v(_vm._s(node_s1.label))]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "ruge-label-content-s2" },
                          _vm._l(node_s1.children, function (node_s2) {
                            return _c(
                              "el-checkbox",
                              {
                                key: node_s2.id,
                                staticClass: "ruge-label-btn-s",
                                attrs: {
                                  disabled: _vm.checkDisabled,
                                  label: node_s2.label,
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.renderCheck(node_s2)
                                  },
                                },
                                model: {
                                  value: node_s2.check,
                                  callback: function ($$v) {
                                    _vm.$set(node_s2, "check", $$v)
                                  },
                                  expression: "node_s2.check",
                                },
                              },
                              [_vm._v(_vm._s(node_s2.label))]
                            )
                          }),
                          1
                        ),
                      ]
                    )
                  }),
                ],
                2
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }